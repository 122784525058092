<template>
    <div class="card bg-light p-2">
        <b-form-row>
            <b-col
                sm="6"
                class="mb-2 mb-sm-0"
            >
                <b-card
                    bg-variant="primary"
                    text-variant="white"
                    class="card-min-h"
                    body-class="d-flex flex-column justify-content-between"
                >
                    <router-link
                        :to="{
                            name: 'cups.show',
                            params: {id: cup.id},
                        }"
                    >
                        <h3>{{ cup.name }}</h3>
                    </router-link>
                </b-card>
            </b-col>

            <b-col>
                <b-card
                    class="card-min-h"
                    body-class="d-flex flex-column justify-content-between"
                >
                    <h3>{{ $t('players') }}</h3>
                </b-card>
            </b-col>

            <b-col>
                <b-card
                    class="card-min-h"
                    body-class="d-flex flex-column justify-content-between"
                >
                    <h3>{{ $t('course') }}</h3>
                </b-card>
            </b-col>
        </b-form-row>
    </div>
</template>

<script>
import Cup from '@/models/Cup';

/**
 * Card representation of a Cup instance.
 * TODO: Finish this component when design is ready.
 */
export default {
    name: 'CupCard',
    props: {
        cup: {
            type: Cup,
            required: true,
        },
    },
};
</script>
