<template>
    <div class="card bg-light p-2">
        <b-form-row>
            <b-col
                md="6"
                class="mb-2 mb-md-0"
            >
                <!-- General Info Card -->
                <b-card
                    class="card-md-min-h bg-cover bg-overlay overflow-hidden"
                    body-class="d-flex flex-column"
                    :style="{ backgroundColor: league.card_background_color, backgroundImage: `url('${league.card_background_image}')`}"
                    text-variant="white"
                >
                    <router-link
                        :to="league.getLocation('schedule.index')"
                        class="mb-3 mb-md-auto"
                    >
                        <h3>{{ league.name }}</h3>
                    </router-link>

                    <div class="d-flex flex-column flex-md-row align-items-md-end">
                        <b-form-group
                            :label="league.getLabel('handicap_type')"
                            class="plaintext mb-md-0 mr-3"
                        >
                            {{ league.handicap_type }}
                        </b-form-group>

                        <b-form-group
                            :label="league.getLabel('team_match_format_type')"
                            class="plaintext mb-0"
                        >
                            {{ league.team_match_format_type }}
                        </b-form-group>

                        <b-btn
                            class="btn-circle ml-auto"
                            :to="league.getLocation('schedule.index')"
                        >
                            <i class="fa fa-chevron-right" />
                        </b-btn>
                    </div>
                </b-card>
            </b-col>

            <b-col
                sm="6"
                md="3"
                class="mb-2 mb-sm-0"
            >
                <!-- Teams Card -->
                <b-card
                    class="card-md-min-h"
                    body-class="d-flex flex-column justify-content-between"
                >
                    <router-link
                        :to="teamsLocation"
                        class="mb-auto text-dark"
                    >
                        <h3>{{ league.getLabel('league_group_teams') }}</h3>
                    </router-link>

                    <b-form-group
                        :label="league.getLabel('league_groups')"
                        class="plaintext text-primary"
                    >
                        {{ league.league_groups_count }}
                    </b-form-group>

                    <div class="d-flex align-items-end">
                        <b-form-group
                            :label="league.getLabel('league_group_teams')"
                            class="plaintext text-primary mb-0"
                        >
                            {{ league.league_group_teams_count }}/{{ league.max_group_teams }}
                        </b-form-group>

                        <b-btn
                            class="btn-circle ml-auto"
                            :to="teamsLocation"
                        >
                            <i class="fa fa-chevron-right" />
                        </b-btn>
                    </div>
                </b-card>
            </b-col>

            <b-col
                sm="6"
                md="3"
            >
                <!-- Schedule Card -->
                <b-card
                    class="card-md-min-h"
                    body-class="d-flex flex-column justify-content-between"
                >
                    <router-link
                        :to="scheduleLocation"
                        class="text-dark"
                    >
                        <h3>{{ scheduleCardTitle }}</h3>
                    </router-link>

                    <label class="mb-auto">
                        {{ scheduleCardText }}
                    </label>

                    <b-btn
                        class="btn-circle ml-auto"
                        :to="scheduleLocation"
                    >
                        <i class="fa fa-chevron-right" />
                    </b-btn>
                </b-card>
            </b-col>
        </b-form-row>
    </div>
</template>

<style scoped>
>>> .col-form-label {
    color: #fbfbfb;
}
</style>

<script>
import Me from '@/library/Me';
import {League} from '@/models/League';
import LeagueStatus from '@/library/enums/LeagueStatus';

/**
 * Card representation of a League instance.
 */
export default {
    name: 'LeagueCard',
    props: {
        league: {
            type: League,
            required: true,
        },
    },
    data() {
        return {
            me: new Me,
        };
    },
    computed: {
        /**
         * Determines whether the current authenticated user can edit the league.
         */
        canEditLeague() {
            const isAdmin = this.me.hasRole('admin');

            const isLeagueAdmin = _.get(
                this.league,
                'current_user_league_user.roles',
                [],
            ).includes('admin');

            return isAdmin || isLeagueAdmin;
        },

        /**
         * Determines whether to link to `leaderboard` or `teams.edit`.
         */
        teamsLocation() {
            // TODO: When `teams.index` page is ready, link to that page instead of
            // `leaderboard`.
            let locationName = 'leaderboard';

            if (
                this.canEditLeague
                && this.league.status === LeagueStatus.WAITING_FOR_TEAMS
            ) {
                locationName = 'teams.edit';
            }

            return this.league.getLocation(locationName);
        },

        /**
         * Determines whether to link to `schedule.index` or `schedule.edit`.
         */
        scheduleLocation() {
            let action = 'index';

            if (this.canEditLeague) {
                action = 'edit';
            }

            return this.league.getLocation(`schedule.${action}`);
        },

        /**
         * Determines which title to display in the schedule card.
         */
        scheduleCardTitle() {
            let text = 'leagues.schedule.index.title';

            if (this.canEditLeague && this.league.has_started) {
                text = 'matches';
            }

            return this.$t(text);
        },

        /**
         * Determines which text to display in the schedule card.
         */
        scheduleCardText() {
            let text = '';

            if (this.canEditLeague) {
                switch (this.league.status) {
                    case LeagueStatus.WAITING_FOR_TEAMS:
                        text = 'leagues.messages.input_teams_then_generate_matchdays';
                        break;

                    case LeagueStatus.WAITING_FOR_MATCHDAYS:
                        text = 'leagues.messages.update_schedule_time_location';
                        break;

                    default:
                        text = 'leagues.messages.verify_match_scores';
                }
            }

            return this.$t(text);
        },
    },
};
</script>
