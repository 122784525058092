import {Model, Collection} from './vue-mc/Model';

export class Competition extends Model {
    /**
     * Default attributes that define the "empty" state.
     *
     * @return {object}
     */
    defaults() {
        return {
            type: null,
            data: {},
        };
    }

    /**
     * Options of this model.
     *
     * @return {object}
     */
    options() {
        return {
            endpoint: 'competitions',
            identifier: null,
        };
    }
};

export class Competitions extends Collection {
    /**
     * Options of this collection.
     *
     * @return {object}
     */
    options() {
        return {
            model: Competition,
        };
    }
};
