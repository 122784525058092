<script>
import Cup from '@/models/Cup';
import {League} from '@/models/League';
import CupCard from '@/components/cups/Card';
import {Competition} from '@/models/Competition';
import LeagueCard from '@/components/leagues/Card';

/**
 * Card representation of a Competition instance. This component will check
 * the competition type and render the respective card component.
 */
export default {
    name: 'CompetitionCard',
    props: {
        competition: {
            type: Competition,
            required: true,
        },
    },
    render: function(createElement) {
        let cardComponent, cardProps;

        switch (this.competition.type) {
            case 'leagues':
                const league = new League(this.competition.data);
                cardComponent = LeagueCard;
                cardProps = {league};
                break;

            case 'cups':
                const cup = new Cup(this.competition.data);
                cardComponent = CupCard;
                cardProps = {cup};
                break;

            default:
                throw new Error('Competition type is unknown.');
                return;
        }

        return createElement(cardComponent, {
            props: cardProps,
        });
    },
};
</script>
