<template>
    <div>
        <h1 class="mb-5">
            {{ $t('home.competitions.title') }}
        </h1>

        <div
            v-infinite-scroll="fetchCompetitions"
            class="card-stack"
        >
            <competition-card
                v-for="(c, i) in competitions.models"
                :key="`c-${i}`"
                :competition="c"
            />

            <div class="text-center">
                <loader :loading="competitions.loading" />
            </div>
        </div>
    </div>
</template>

<script>
import Me from '@/library/Me';
import {Competitions} from '@/models/Competition';
import CompetitionCard from '@/components/competitions/Card';

export default {
    name: 'Home',
    components: {CompetitionCard},
    data() {
        return {
            me: new Me,
            competitions: [],
        };
    },
    created() {
        // If the current user is an admin, we fetch from `/competitions`.
        // Else, we fetch from `/users/me/competitions`.
        this.competitions = new Competitions([], {
            routePrefix: this.me.hasRole('admin') ? '' : 'users/me',
        });

        this.competitions.page(1);

        this.fetchCompetitions();
    },
    methods: {
        async fetchCompetitions() {
            if (!this.competitions.loading) {
                await this.competitions.fetch();

                // If the last page is reached, return false to stop the infinite scroll.
                if (this.competitions.isLastPage()) return false;
            }
        },
    },
};
</script>
